ul {
  list-style-type: none;
  padding: 0;
}

li {
  list-style-type: none;
}

.ais-Hits-item {
  box-shadow: none;
}

#fulfillment-choices {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 10px;
}

#product-quantity-search-controls {
  display: flex;
  flex-direction: row;
}

#product-quantity-search-controls input {
  border: 0;
  border-bottom: 1px solid black;
  text-align: center;
  width: 30px;
  font-size: 20px;
}

.quantity-modifer {
  background-color: #817e7e;
  color: white;
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.search {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

h3 {
  font-size: 1em;
  margin: 0;
  padding: 0;
}

.search-refiners {
  padding: 1rem;
  width: 200px;
  min-width: 200px;
}

.state-results {
  width: 100%;
}

.results-area {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.search-results {
  grid-gap: 35px;
  display: grid;

  grid-template-columns: repeat(auto-fill, 320px);
  justify-content: center;
  /* padding-top: 35px; */
  width: 100%;
}

.search-refiners > div {
  margin-bottom: 2vh;
}

.pagination {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 3rem;
  margin-top: 3rem;
  text-align: center;
  justify-self: center;
  align-self: center;
}

.search-refiners-toggle {
  display: none;
}

@media (max-width: 500px) {
  .search-refiners-toggle {
    display: flex;
  }

  .search {
    flex-direction: column;
    margin-top: var(--header-height);
    padding: 0px;
  }

  .search-refiners {
    display: none;
    width: 100%;
  }
}
