#footer {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#footer a {
  color: var(--text-and-frames-color);
}

#footer img {
  width: 130px;
}

#footer h1 {
  font-size: 1.5rem;
}

#footer > div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1em;
  height: var(--footer-height);
  justify-content: center;
}

#footer > div:nth-child(1) {
  background-color: var(--color-2-taupe);
  display: flex;
  justify-content: center;
  align-items: center;
}

#footer > div:nth-child(2) {
  background-color: var(--color-2-taupe);
}

#footer > div:nth-child(3) {
  background-color: var(--color-3-yellow);
}

#footer > div:nth-child(4) {
  background-color: var(--color-4-peach);
}

#footer > div:nth-child(5) {
  background-color: var(--color-5-tangerine);
}
