/* .content>div {
  padding: .5em;
  box-sizing: border-box;
  max-width: 100vw;
} */

textarea {
  width: 100%;
}

#profile-link {
  display: flex;
}

.filepicker {
  border-color: var(--producer-color) !important;
  font-family: inherit !important;
  padding: 0 !important;
}

.dropzone .dz-preview .dz-image {
  width: 60px !important;
  height: 60px !important;
}

.dropzone .dz-preview .dz-details {
  display: none !important;
}

.dropzone .dz-preview:hover .dz-image img {
  filter: none !important;
  -webkit-filter: none !important;
}

.dropzone .dz-preview {
  margin: 0 !important;
  margin-top: 5px !important;
  min-height: 50% !important;
}

#business-hero-image {
  max-width: 100%;
}
