.FacetComponent-container {
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  display: flex;
  font-size: 0.75em;
}

.FacetComponent {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-around;
  align-items: center;
  border: 4px solid #f0f0f0;
  height: 30px;
  margin: 5px;
}

.FacetComponent .facetName {
  background-color: black;
  background-color: #f0f0f0;
  color: #333;
  display: flex;

  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 2px;
  box-sizing: border-box;
}

/* container of many facets */
.FacetComponent .facets {
  display: flex;
  height: 100%;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-direction: row;
  background-color: #f0f0f0;
}

.facet {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 0.8rem;
  white-space: nowrap;
  background-color: white;
  margin: 5px;
  padding: 0.2rem;
}
