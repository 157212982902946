.banner {
  position: relative;
  justify-content: center;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
}

.banner-content {
  display: flex;
  flex-direction: column;
}
.warning .icon {
  min-width: 50px;
  min-height: 50px;
  margin-right: 10px;
  background-image: url('../../../public/icons/caution2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.banner p {
  margin: 0;
}

.banner .button {
  display: flex;
  width: unset;
  margin-top: 10px;
  background-color: var(--spirit-color);
}
.dismiss-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: black;
}

.warning {
  background-color: var(--color-4-peach);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
}

.warning p:first-child {
  margin-top: 0;
  font-weight: bold;
  color: var(--color-1-dark-brown);
}
