#fulfillment-preferences{
    display: flex;
    flex-direction: column;
}
#fulfillment-page{
    background-color:rgb(230, 230, 230);
}

#fulfillment-page > div > div {
    background-color:rgb(208, 208, 208);
    margin:1vw;
} 
#fulfillment-preferences > div {
    display: flex;
    flex-direction: column;
}

#ship-to-state-container {
    display:grid;
    grid-template-columns: repeat(auto-fill, 210px); /* see notes below */
}
.tax-state-container {
    display:flex;
    flex-direction: column;
}
.tax-state {
    display: flex;
    flex-direction: row;
}
.tax-rate {
    display:flex;
    flex-direction: column;
    font-size:.8em;
}

#delivery-options {
    display:flex;
    flex-direction: column;
}

.delivery-options {
    display:flex;
    flex-direction: column;
}