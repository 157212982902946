.social-icons {
  display: flex;
  flex-direction: row;
}

.social-icons a {
  margin: 10px;
}
.banner-container {
  height: 300px;
}
.business-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.business-website-phone {
  text-align: right;
}
.banner-container img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  object-position: 0% 80%;
}

.business-products {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.business-header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
