.bust {
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.about {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.about .above-fold {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 4vw;
  background-size: cover;
  background-position: bottom center;
  /* height: calc(100vh - var(--header-height)); */
}

.above-fold > div {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 3vw;
  padding: 1vw;
}

.about .below-fold {
  display: flex;
  flex-direction: column;
  object-fit: contain;
  background-position: center center;
  padding: 4vw;
}

.bio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
}

.bio {
  display: flex;
  flex-direction: column;
  padding: 1em;
  min-width: 358px;
  width: 30%;
  max-width: 700px;
}

.bust img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center center;
}

a {
  text-decoration: none;
}

h1 a {
  color: black;
  cursor: default;
}
