#our-sidebar {
  height: 100%;
}

#logout-container {
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

#logout-link {
  background-color: var(--spirit-color);
  padding: 5px 0px;
  margin: 10px auto;
}

#add-new-business-button {
  margin: 10px auto;
  justify-self: center;
  width: 10rem;
}
