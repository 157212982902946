#business-navigation {
    min-width:120px;
    margin-left:2rem;
}
#business-navigation a {
    color:black;
}
@media only screen and (max-width: 550px) {
    div.business-nav {
     display:none;
    }
  }