.sell ul li {
  text-transform: capitalize;
  list-style-type: square;
}

.sell ul {
  padding: 2vw;
}

.below-fold {
  min-height: calc(100vh - var(--footer-height));
  padding: 4vw;
}

.benefit-tile-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.benefits-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.benefit-tile {
  width: clamp(220px, 11vw, 350px);
  flex-grow: 1;

  padding: 1vw;
}

.benefit-tile:nth-child(1) {
  background-color: var(--color-1-dark-brown);
}

.benefit-tile:nth-child(2) {
  background-color: var(--color-2-taupe);
}

.benefit-tile:nth-child(3) {
  background-color: var(--color-3-yellow);
}

.benefit-tile:nth-child(4) {
  background-color: var(--color-4-peach);
}

.headline {
  display: flex;
  flex-direction: row;
}

.headline > span:first-child {
  width: 80%;
}

.split-row {
  background: linear-gradient(
    148deg,
    rgba(213, 213, 217, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 4vw;
}

.split-row > * {
  width: 50%;
  min-width: 350px;
  height: 100%;
}

.bottles {
  display: flex;
  align-items: center;
  align-self: center;
}

.split-row img {
  margin: 0 auto;
  max-width: 380px;
  object-fit: contain;
  display: flex;
  align-self: center;
}
