.varietals {
  display: block !important;
}
.product-quantity-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  font-size: 1.4em;
  align-items: center;
}
.edit-product-button {
  background-color: var(--color-4-peach);
}
.product-quantity-buttons > div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  padding: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  overflow: hidden;
  background-color: #2f2123;
  cursor: pointer;
  font-weight: bold;
  color: white;
}

.product-producer-name {
  overflow: hidden;
  display: flex;
}

.product-tile {
  display: flex;
  flex-direction: column;
  height: 360px;
  margin: 1rem;
  width: 320px;
  position: relative;
}

.product-producer-name-link {
  color: var(--primary-color);
  font-size: 1.3rem;
}

.product-tile-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  padding: 10px;
  border-bottom: 0;
}

.product-tile-below-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  border: 1px solid black;
  justify-content: space-around;
}

.product-icon-rectangle svg {
  color: white !important;
}

.product-pricing {
  display: none;
}

.pricing-box {
  margin-top: auto;
  background-color: #dde6d1;
  margin-right: auto;
  width: 13rem;
  font-size: 0.8rem;
}
.tag-values {
  display: none;
}

.add-to-cart {
  background-color: black;
  border: 1px solid black;
  color: white;
  margin: 10px;
  padding: 7px;
  font-size: 13px;
  cursor: pointer;
  width: 4.75rem;
}
.add-remove-product-to-cart {
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  margin-top: auto;
}
.bottom-row {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.volume-abv-and-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}

.volume-abv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-content: center;
}

.product-producer {
  width: 120%;
  z-index: 2;
}

.new-tab-icon {
  width: 15px;
  margin-left: 5px;
  height: 15px;
}

.product-image-section {
  display: flex;
}

.product-image {
  object-fit: contain;
  max-height: 150px;
}

.product-secondary-details {
  font-size: 0.8rem;
}

/* In case of a missing image, the icon is shown where the bottle image would be */

.product-image-section .product-icon {
  height: 80%;
}

.product-icon-section {
  display: flex;
  justify-content: flex-end;
}

.product-icon-rectangle {
  width: 60px;
  /* overflow: hidden; */
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.search .cls-1 {
  stroke: white !important;
}
.product-icon-title {
  font-size: 0.6em;
  text-align: center;
}
.product-image-section > div {
  display: none;
  /* hides the abv and volume divs */
}

.product-icon-svg {
  display: flex;
  height: 50%;
  justify-content: center;
}

.product-icon-svg svg {
  width: 35%;
  fill: white;
}

.tile-add-icon {
  background-color: black;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  text-align: center;
  position: absolute;
  line-height: 21px;
}

.modal.isOpen::before {
  content: '';
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  animation: moveUp 0.3s;
}

.modal-add::before {
  content: '';
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  animation: moveUp 0.3s;
  align-self: flex-start;
  justify-self: start;
  margin-right: -20px;
}

.modal-info::before {
  content: '';
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.product-tile-img {
  max-height: 210px;
}

@keyframes moveUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 575.98px) {
  .product-producer img {
    margin: 0;
  }
}
